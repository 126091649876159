/* eslint-disable */
import { lazy } from 'react';
import { DEFAULT_PATHS, IS_PERMISSION } from 'config.js';

const grantDatas = [...IS_PERMISSION];
const privateRoutes = [];

const news = lazy(() => import('views/news/Blog'));

const accounts = {
  accountlist: lazy(() => import('views/accounts/Accounts')),
  add: lazy(() => import('views/accounts/Add')),
  edit: lazy(() => import('views/accounts/Edit')),
};

const dashboard = {
  main: lazy(() => import('views/dashboard/Main')),
};

const projects = {
  projectlist: lazy(() => import('views/projects/Projects')),
  details: lazy(() => import('views/projects/Details')),
};

const strings = {
  stringlist: lazy(() => import('views/strings/Strings')),
  details: lazy(() => import('views/strings/Details')),
};

const diagrams = {
  diagramlist: lazy(() => import('views/diagrams/Diagrams')),
  details: lazy(() => import('views/diagrams/Details')),
};

const permissions = {
  menuList: lazy(() => import('views/permissionMenus/Generatetors')),
};

const manageDevices = {
  devices: lazy(() => import('views/menages/devices/DeviceManagements')),
  create: lazy(() => import('views/menages/devices/DeviceMaintains')),
  edit: lazy(() => import('views/menages/devices/DeviceMaintains')),
};

const manageSites = {
  sites: lazy(() => import('views/menages/sites/SiteManagements')),
  create: lazy(() => import('views/menages/sites/SiteMaintains')),
  edit: lazy(() => import('views/menages/sites/SiteMaintains')),
};

const roles = {
  role: lazy(() => import('views/roles/Role')),
  create: lazy(() => import('views/roles/Creates')),
  edit: lazy(() => import('views/roles/Edits')),
};

const siteMonitoring = {
  siteMonitor: lazy(() => import('views/site-monitorings/SiteMonitorings')),
  details: lazy(() => import('views/site-monitorings/Details')),
};

const billings = {};

const detections = {
  plants: lazy(() => import('views/detections/Plants')),
  details: lazy(() => import('views/detections/Details')),
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

// Publish menu items
const mainItemRoutes = [
  {
    path: DEFAULT_PATHS.APP,
    exact: true,
    redirect: true,
    to: `${appRoot}/dashboard/getting-started`,
  },
  {
    path: `${appRoot}/news`,
    component: news,
    label: 'menu.news-events',
    icon: 'file-text',
  },
];

const publishRoutes = [
  {
    path: `${appRoot}/dashboard`,
    label: 'menu.dashboard',
    icon: 'grid-1',
    exact: true,
    redirect: true,
    to: `${appRoot}/dashboard/getting-started`,
    subs: [{ path: '/getting-started', label: 'menu.getting-started', icon: 'dashboard-1', component: dashboard.main }],
  },
];

export const subRoutes = [
  {
    grantId: '200000',
    name: 'Plants',
    path: `${appRoot}/plants`,
    label: 'menu.plants',
    icon: 'grid-1',
    exact: true,
    redirect: true,
    to: `${appRoot}/plants/projects`,
    subs: [
      {
        grantId: '200000-6',
        path: '/detections',
        label: 'menu.detections',
        icon: 'accordion',
        component: detections.plants,
        subs: [{ path: '/:plant', hideInMenu: true, component: detections.details }],
      },
      {
        grantId: '200000-1',
        path: '/projects',
        label: 'menu.projects',
        icon: 'antenna',
        component: projects.projectlist,
        subs: [{ path: '/details/:projectname', hideInMenu: true, component: projects.details }],
      },
      {
        grantId: '200000-2',
        path: '/strings',
        label: 'menu.strings',
        icon: 'grid-1',
        component: strings.stringlist,
        subs: [{ path: '/details', hideInMenu: true, component: strings.details }],
      },
      {
        grantId: '200000-3',
        path: '/diagrams',
        label: 'menu.diagrams',
        icon: 'diagram-1',
        component: diagrams.diagramlist,
        subs: [{ path: '/details', hideInMenu: true, component: diagrams.details }],
      },
      {
        grantId: '200000-4',
        path: '/alarms',
        label: 'menu.alarms',
        icon: 'alarm',
        component: projects.project,
        subs: [],
      },
      {
        grantId: '200000-5',
        path: '/site-monitorings',
        label: 'menu.stie-monitorings',
        icon: 'leaf',
        component: siteMonitoring.siteMonitor,
        subs: [{ path: '/:id', hideInMenu: true, component: siteMonitoring.details }],
      },
    ],
  },
  {
    grantId: '300000',
    name: 'Managments',
    path: `${appRoot}/mangements`,
    label: 'menu.managements',
    icon: 'grid-1',
    exact: true,
    redirect: true,
    to: `${appRoot}/settings`,
    subs: [
      {
        grantId: '300000-1',
        path: '/projects',
        label: 'menu.projects',
        icon: 'antenna',
        component: projects.project,
        subs: [],
      },
      {
        grantId: '300000-2',
        path: '/strings',
        label: 'menu.strings',
        icon: 'grid-1',
        component: projects.project,
        subs: [],
      },
      {
        grantId: '300000-3',
        path: '/news-events',
        label: 'menu.news-events',
        icon: 'file-text',
        component: projects.project,
        subs: [],
      },
      {
        grantId: '300000-4',
        path: '/devices',
        label: 'menu.devices',
        icon: 'toy',
        component: manageDevices.devices,
        subs: [
          { path: '/edit/:id', hideInMenu: true, component: manageDevices.edit },
          { path: '/create', hideInMenu: true, component: manageDevices.create },
        ],
      },
      {
        grantId: '300000-5',
        path: '/sites',
        label: 'menu.sites',
        icon: 'home-garage',
        component: manageSites.sites,
        subs: [
          { path: '/edit/:id', hideInMenu: true, component: manageSites.edit },
          { path: '/create', hideInMenu: true, component: manageSites.create },
        ],
      },
    ],
  },
  {
    grantId: '100000',
    name: 'Settings',
    path: `${appRoot}/settings`,
    label: 'menu.settings',
    icon: 'grid-1',
    exact: true,
    redirect: true,
    to: `${appRoot}/settings`,
    subs: [
      {
        grantId: '100000-1',
        name: 'Permission Menu',
        path: '/grant-menus',
        label: 'menu.permission-menus',
        icon: 'collapse',
        component: permissions.menuList,
        subs: [],
      },
      {
        grantId: '100000-2',
        path: '/roles',
        label: 'menu.roles',
        icon: 'controller',
        component: roles.role,
        subs: [
          { path: '/edit/:code', hideInMenu: true, component: roles.edit },
          { path: '/create', hideInMenu: true, component: roles.create },
        ],
      },
      {
        grantId: '100000-3',
        path: '/accounts',
        label: 'menu.accounts',
        icon: 'user',
        component: accounts.accountlist,
        subs: [
          { path: '/edit/:username', hideInMenu: true, component: accounts.edit },
          { path: '/create', hideInMenu: true, component: accounts.add },
        ],
      },
    ],
  },
];

subRoutes.map((item = []) => {
  const items = { ...item };
  const subroutes = [];
  if (items.grantId) {
    const isPermiss = grantDatas.indexOf(items.grantId.toString());
    if (isPermiss !== -1) {
      items.subs.forEach((ele) => {
        if (grantDatas.indexOf(ele.grantId) !== -1) {
          subroutes.push(ele);
        }
      });
      items.subs = [...subroutes];
      privateRoutes.push(items);
    }
  }
});

const routesAndMenuItems = {
  mainMenuItems: mainItemRoutes,
  sidebarItems: [...publishRoutes, ...privateRoutes],
};

export default routesAndMenuItems;
