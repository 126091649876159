const enMessages = {
  'menu.dashboard': 'Dashboard',
  'menu.getting-started': 'Get Started',
  'menu.dashboards': 'Dashboards',
  'menu.plants': 'Plants',
  'menu.projects': 'Projects',
  'menu.news': 'News',
  'menu.news-events': 'News & Events',
  'menu.strings': 'Strings',
  'menu.settings': 'Settings',
  'menu.accounts': 'Accounts',
  'menu.managements': 'Managements',
  'menu.roles': 'Roles',
  'menu.promotions': 'Promotions',
  'menu.diagrams': 'Diagrams',
  'menu.alarms': 'Alarms',
  'menu.permission-menus': 'Permission Menus',
  'menu.devices': 'Devices',
  'menu.sites': 'Sites',
  'menu.stie-monitorings': 'Site Monitorings',
  'menu.detections': 'Detections',
};
export default enMessages;
