import axios from 'axios';
import { base64 } from 'utils';

const token = localStorage.accessToken;

const clearTokenStore = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('permission');
};

const authLogout = () => {
  if (token) {
    clearTokenStore();
  }
  window.location.href = '/login';
  return false;
};

const authLogin = (username, password) => {
  const datas = {
    username,
    password,
  };
  const BASE_AUTH_AUTHEN = `${process.env.REACT_APP_SERVICE_URL}/auth/login`;
  return axios.post(BASE_AUTH_AUTHEN, datas);
};

const verifyToken = async (user) => {
  const tokenExp = user.exp * 1000;
  const currDate = new Date().getTime();
  if (tokenExp < currDate) {
    authLogout();
  }
  const AUTH_URL_VERIFY = `${process.env.REACT_APP_SERVICE_URL}/auth/verify`;
  await axios
    .post(AUTH_URL_VERIFY, { token })
    .then((res) => {
      localStorage.setItem('accessToken', res.data.results);
      localStorage.setItem('permission', base64.utf8ToB64(res.data.permissions));
    })
    .catch((error) => {
      if (error) {
        window.location.href = '/login';
      } else {
        window.location.href = '/';
      }
    });
};

export default {
  authLogin,
  authLogout,
  verifyToken,
};
