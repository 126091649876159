import { LAYOUT, MENU_BEHAVIOUR, NAV_COLOR, MENU_PLACEMENT, RADIUS, THEME_COLOR } from 'constants.js';
import jwtDecode from 'jwt-decode';
import { base64 } from 'utils';

// For detailed information: https://github.com/nfl/react-helmet#reference-guide
export const REACT_HELMET_PROPS = {
  defaultTitle: 'Esma',
  titleTemplate: '%s | Esma',
};

const token = localStorage.accessToken || '';

export const getAccoutProfile = () => {
  let data = {};
  if (token) {
    const user = jwtDecode(token);
    data = {
      id: user._id,
      image: user.image,
      thumb: user.image,
      name: `${user.firstname} ${user.lastname}`,
      email: user.email,
    };
  }

  return data;
};

export const checkAuthen = () => {
  if (token) {
    return true;
  }
  return false;
};

export const checkPermission = () => {
  let arrPermission = [];
  let permission = localStorage.getItem('permission');

  if (permission) {
    permission = base64.b64ToUtf8(permission);
    arrPermission = permission.split(',');
  }

  return arrPermission;
};

export const IS_AUTHEN = checkAuthen();
export const IS_PERMISSION = checkPermission();
export const IS_AUTH_GUARD_ACTIVE = true;
export const SERVICE_URL = '/app';
export const USE_MULTI_LANGUAGE = true;

export const DEFAULT_PATHS = {
  APP: '/',
  LOGIN: '/login',
  USER_WELCOME: '/dashboards/default',
  NOTFOUND: '/page-not-found',
  UNAUTHORIZED: '/unauthorized',
  INVALID_ACCESS: '/invalid-access',
};

export const DEFAULT_SETTINGS = {
  MENU_PLACEMENT: MENU_PLACEMENT.Horizontal,
  MENU_BEHAVIOUR: MENU_BEHAVIOUR.Pinned,
  LAYOUT: LAYOUT.Boxed,
  RADIUS: RADIUS.Rounded,
  COLOR: THEME_COLOR.LightGreen,
  NAV_COLOR: NAV_COLOR.Light,
  USE_SIDEBAR: true,
};

export const DEFAULT_USER = getAccoutProfile();

export const REDUX_PERSIST_KEY = 'esma-project';
