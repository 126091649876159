const enMessages = {
  'menu.dashboard': 'Dashboard',
  'menu.getting-started': 'Get Started',
  'menu.dashboards': 'Dashboards',
  'menu.plants': 'Plants',
  'menu.projects': 'Projects',
  'menu.news': 'News',
  'menu.news-events': 'News & Events',
  'menu.strings': 'Strings',
  'menu.settings': 'Settings',
  'menu.accounts': 'Accounts',
  'menu.managements': 'Managements',
  'menu.roles': 'Roles',
  'menu.promotions': 'Promotions',
  'menu.diagrams': 'แผนภาพ',
  'menu.alarms': 'การแจ้งเตือน',
  'menu.permission-menus': 'การจัดการการเข้าถึง',
  'menu.stie-monitorings': 'การมอนิเตอร์อุปกรณ์',
  'menu.detections': 'การตรวจจับ',
};
export default enMessages;
